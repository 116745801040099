'use client';

/* eslint-disable @next/next/no-img-element */
import React from 'react';
import HeaderLinks from '../HeaderLinks';
import { PROJECTS_ROUTES, ROUTES } from '@/constants/routes/default';
import { buildIntroviewUrl } from '@/helpers/routesHelpers';
import Link from 'next/link';

function HeaderKG() {
  const introviewUrl = buildIntroviewUrl({
    shortProjectName: PROJECTS_ROUTES.kimbleGroup.shortPath,
    projectName: 'kimble-group',
  });

  return (
    <header className="flex md:px-10 max-md:px-4 min-h-[80px] border-b border-main-text-color">
      <div className="max-w-[1240px] w-full h-full flex justify-between items-center m-auto flex-wrap gap-4">
        <Link href={ROUTES.home}>
          <img
            width="144"
            height="12"
            src="https://assets.kimblegroup.com/kimblegroup/2024-04/logo_v2.png"
            alt="Logo"
          />
        </Link>
        <HeaderLinks
          introviewUrl={introviewUrl}
          withCenteredItems
          listHeaderCn="px-3.5 py-2 text-page-text-color2"
          dropdownClassNames={{
            listCn:
              'max-w-[200px] right-0 bg-white py-2 border border-main-text-color custom-shadow-kg',
            elementCn:
              'text-sm text-main-text-color h-[34px] px-4 flex items-center hover:bg-athens-gray',
            rootCn: '',
            iconCn:
              'h-10 w-10 border border-main-border-color hover:border-main-text-color [&>svg>path]:!fill-black',
            activeIconCn: 'border border-main-text-color',
            dividerCn: 'mx-4',
          }}
          componentCn="flex gap-5 items-center"
          linkCn="uppercase text-main-text-color text-sm p-2.5 border border-transparent default-transition hover:border-main-text-color"
        />
      </div>
    </header>
  );
}

export default HeaderKG;
